import * as React from 'react';
import Grid from '@mui/material/Grid';

export default function Main() {
    return (
        <Grid container spacing={5}>
            <Grid item>
                <p style={{textAlign: "justify"}}>
                    Функционал ИАС «Интерактивный справочник для сравнения инструментов» позволяет осуществлять подбор информации для текстового сравнения нескольких инструментов (количество определяет пользователь) по заданным параметрам (количество определяет пользователь) и выводить отчет по параметрам сравнения в виде таблицы, в том числе и для печати
                </p>
                <p style={{textAlign: "justify"}}>
                    ИАС также снабжена вспомогательными разделами – Этапы реализации инструмента (Time-line) и Глоссарий
                </p>
                <p style={{textAlign: "justify"}}>
                    Данные, размещенные в ИАС, могут использоваться в подготовке аналитических материалов, в совершенствовании правового регулирования, оказании консультационных и экспертных услуг.
                </p>
            </Grid>
        </Grid>
    );
}
