// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".map_map__1jkrY {\n    width: 100%;\n    height: 600px;\n    border: 0;\n}", "",{"version":3,"sources":["webpack://./src/pages/map/map.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,SAAS;AACb","sourcesContent":[".map {\n    width: 100%;\n    height: 600px;\n    border: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"map": "map_map__1jkrY"
};
export default ___CSS_LOADER_EXPORT___;
